"use client";

import { Dispatch, FC, SetStateAction } from "react";
import { useTranslations } from "~/hooks/useTranslations";
import { Dialog, DialogContent, DialogTitle } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCartShopping,
  faCheck,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { AddOnContent } from "./AddOnContent";
import { AddOnSelections } from "./AddOnSelections";

import Link from "next/link";
import { PlainMessage } from "@bufbuild/protobuf";
import { ShopConfiguration } from "@egocentric-systems/ts-apis/booking_gateway/v1/shop_config_pb";
import { cn } from "~/lib/utils";
import { Paths } from "~/lib/Paths";

interface Props {
  isOpen: boolean;
  close: () => void;
  step: string;
  setStep: (step: string) => void;
  showBackArrow: boolean;
  showTitleSteps: boolean;
  showContinueButton: boolean;
  handleSaveFantickets: () => void;
  continueTo: string;
  checkedFilteredItems: any[];
  setCheckedFilteredItems: Dispatch<SetStateAction<number[]>>;
  filteredItems: any[];
  shopConfig: PlainMessage<ShopConfiguration>;
  showOnCart: boolean;
}

export const AddOnPopoverDesktop: FC<Props> = ({
  isOpen,
  close,
  step,
  setStep,
  showBackArrow,
  showTitleSteps,
  showContinueButton,
  handleSaveFantickets,
  continueTo,
  checkedFilteredItems,
  setCheckedFilteredItems,
  filteredItems,
  shopConfig,
  showOnCart,
}) => {
  const t = useTranslations();

  return (
    <Dialog open={isOpen}>
      <DialogContent
        hideClose
        className="flex h-[800px] flex-col overflow-hidden sm:max-w-[800px]"
      >
        <DialogTitle className="hidden" />
        <div className="mx-[-1.5rem] mt-[-1.5rem] flex flex-none items-center justify-between border-b border-gray-300 bg-[#FAFAFA] p-4">
          <div className="flex w-8 items-center">
            {step === "upgradeSelections" && (
              <Button
                variant="link"
                type="button"
                title={t.back}
                className={cn(
                  !showBackArrow && "hidden",
                  "m-0 h-6 w-4 bg-transparent p-0 text-gray-400",
                )}
                onClick={() => setStep("upgradeInfo")}
              >
                <FontAwesomeIcon icon={faArrowLeft} className="size-5" />
              </Button>
            )}
          </div>
          {showTitleSteps && (
            <div className="flex flex-row justify-between">
              <div className="flex flex-col items-center gap-2">
                <div
                  className={cn(
                    "leading-0 flex size-6 items-center justify-center rounded-full border border-gray-600 text-[0.7rem]",
                    step === "upgradeInfo" && "bg-black text-white",
                  )}
                >
                  {step === "upgradeInfo" ? (
                    1
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="size-2 text-gray-500"
                    />
                  )}
                </div>
                <div className="text-[0.7rem] text-gray-600">
                  {t.upgrade_banner.dialog_step_1_title}
                </div>
              </div>
              <div className="h-3 w-8 border-b border-gray-300" />
              <div className="flex flex-col items-center gap-2">
                <div
                  className={cn(
                    "flex size-6 items-center justify-center rounded-full border border-gray-600 text-[0.7rem]",
                    (step === "upgradeSelections" && "bg-black text-white") ||
                      (step === "upgradeInfo" && "text-gray-500"),
                  )}
                >
                  2
                </div>
                <div className="text-[0.7rem] text-gray-600">
                  {t.upgrade_banner.dialog_step_2_title}
                </div>
              </div>
            </div>
          )}
          <Button
            variant="link"
            onClick={close}
            className="h-6 w-4 bg-transparent p-0 text-gray-400"
            title={t.additional_options.cancel}
          >
            <FontAwesomeIcon icon={faXmark} className="size-5" />
          </Button>
        </div>

        <div className="flex flex-1 flex-col space-y-4 overflow-y-auto">
          {step === "upgradeInfo" ? (
            <AddOnContent isDialog={true} />
          ) : (
            <AddOnSelections
              checkedFilteredItems={checkedFilteredItems}
              setCheckedFilteredItems={setCheckedFilteredItems}
              filteredItems={filteredItems}
            />
          )}
        </div>

        <div className="align-end mx-[-1.5rem] mb-[-1.5rem] flex flex-none justify-between border-t border-gray-100 bg-white p-4">
          <div>
            {showContinueButton && (
              <Link
                href={continueTo === "checkout" ? Paths.CHECKOUT : Paths.CART}
                prefetch={false}
              >
                <Button
                  variant="link"
                  onClick={close}
                  className="p-0 pl-4 text-md text-gray-400"
                >
                  {t.additional_options.continue}
                </Button>
              </Link>
            )}
          </div>
          {step === "upgradeInfo" ? (
            <Button
              variant="default"
              type="button"
              onClick={() => setStep("upgradeSelections")}
              className="text-md text-black"
              style={{
                backgroundColor:
                  shopConfig.commonConfiguration?.sloganTextColor,
              }}
            >
              {t.additional_options.add}
            </Button>
          ) : (
            <Link
              href={continueTo === "checkout" ? Paths.CHECKOUT : Paths.CART}
              prefetch={false}
            >
              <Button
                variant="default"
                className="text-md text-black"
                onClick={handleSaveFantickets}
                style={{
                  backgroundColor:
                    shopConfig.commonConfiguration?.sloganTextColor,
                }}
              >
                <FontAwesomeIcon icon={faCartShopping} className="size-4" />
                {showOnCart
                  ? t.upgrade_banner.add_to_cart
                  : t.upgrade_banner.add_and_proceed_to_cart}
              </Button>
            </Link>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
