"use client";

import { useEffect, useState, type FC, type ReactNode } from "react";
import { useBoolean, useMediaQuery } from "usehooks-ts";
import { useAddOnAvailable } from "~/app/cart/_components/AddOns/useAddOnAvailable";
import { useShopConfig } from "~/hooks/useShopConfig";
import { useCart } from "~/hooks/useCart";
import { CartItem } from "~/lib/definitions";
import { AddOnPopoverMobile } from "./AddonPopoverMobile";
import { AddOnPopoverDesktop } from "./AddonPopoverDesktop";

interface IAddOns {
  continueTo: "checkout" | "cart";
  children: FC<{
    onOpen?: () => void;
  }>;
  showStep?: "upgradeInfo" | "upgradeSelections";
  showBackArrow?: boolean;
  showTitleSteps?: boolean;
  showContinueButton?: boolean;
  showOnCart?: boolean;
}

export function AddOnPopover({
  children,
  continueTo,
  showStep = "upgradeInfo",
  showBackArrow = true,
  showTitleSteps = true,
  showContinueButton = true,
  showOnCart = false,
}: IAddOns): ReactNode {
  const { shopConfig } = useShopConfig();
  const addOnAvailable = useAddOnAvailable();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const [step, setStep] = useState<string>(showStep);
  const { removeFantickets, addFanticket, items } = useCart();

  const [filteredItems, setFilteredItems] = useState<CartItem[]>([]);
  const [checkedFilteredItems, setCheckedFilteredItems] = useState<number[]>(
    [],
  );

  useEffect(() => {
    const newFilteredItems = items.filter((item) =>
      item.eventOrBundle.case === "event"
        ? shopConfig.fanTicket?.eventIds?.includes(item.eventOrBundle.value.id)
        : item.eventOrBundle.value?.eventIds.every((eventId) =>
            shopConfig.fanTicket?.eventIds?.includes(eventId),
          ),
    );

    setFilteredItems(newFilteredItems);

    setCheckedFilteredItems(
      newFilteredItems
        .map((item, index) => (item.upgrades ? index : -1))
        .filter((index) => index !== -1),
    );
  }, [items, isOpen]);

  useEffect(() => {
    if (isOpen && !addOnAvailable) {
      close();
      setCheckedFilteredItems([]);
    }
  }, [isOpen, addOnAvailable, close]);

  const handleSaveFantickets = () => {
    removeFantickets();
    for (const index of checkedFilteredItems) {
      const item = filteredItems[index];
      if (item) {
        addFanticket(item);
      }
    }
    setCheckedFilteredItems([]);
    close();
  };

  return (
    <>
      {isMobile ? (
        <AddOnPopoverMobile
          {...{
            isOpen,
            close,
            step,
            setStep,
            showBackArrow,
            showTitleSteps,
            showContinueButton,
            handleSaveFantickets,
            continueTo,
            checkedFilteredItems,
            setCheckedFilteredItems,
            filteredItems,
            shopConfig,
            showOnCart,
          }}
        />
      ) : (
        <AddOnPopoverDesktop
          {...{
            isOpen,
            close,
            step,
            setStep,
            showBackArrow,
            showTitleSteps,
            showContinueButton,
            handleSaveFantickets,
            continueTo,
            checkedFilteredItems,
            setCheckedFilteredItems,
            filteredItems,
            shopConfig,
            showOnCart,
          }}
        />
      )}
      {children({ onOpen: addOnAvailable ? open : undefined })}
    </>
  );
}
