"use client";

import { Dispatch, FC, SetStateAction } from "react";
import { useTranslations } from "~/hooks/useTranslations";
import { Drawer, DrawerContent, DrawerTitle } from "~/components/ui/drawer";
import { Button } from "~/components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faXmark,
  faCartShopping,
} from "@fortawesome/pro-regular-svg-icons";
import { AddOnContent } from "./AddOnContent";
import { AddOnSelections } from "./AddOnSelections";

import { moneyToCurrency } from "~/lib/utils";
import Link from "next/link";
import { Paths } from "~/lib/Paths";
import { ShopConfiguration } from "@egocentric-systems/ts-apis/booking_gateway/v1/shop_config_pb";
import { PlainMessage } from "@bufbuild/protobuf";

interface Props {
  isOpen: boolean;
  close: () => void;
  step: string;
  setStep: (step: string) => void;
  showBackArrow: boolean;
  showTitleSteps: boolean;
  showContinueButton: boolean;
  handleSaveFantickets: () => void;
  continueTo: string;
  checkedFilteredItems: any[];
  setCheckedFilteredItems: Dispatch<SetStateAction<number[]>>;
  filteredItems: any[];
  shopConfig: PlainMessage<ShopConfiguration>;
  showOnCart: boolean;
}

export const AddOnPopoverMobile: FC<Props> = ({
  isOpen,
  close,
  step,
  setStep,
  showBackArrow,
  showTitleSteps,
  showContinueButton,
  handleSaveFantickets,
  continueTo,
  checkedFilteredItems,
  setCheckedFilteredItems,
  filteredItems,
  shopConfig,
  showOnCart,
}) => {
  const t = useTranslations();

  return (
    <Drawer open={isOpen} dismissible={false}>
      <DrawerContent
        hideBar
        className="flex h-[640px] flex-col overflow-hidden border-0 md:max-h-[800px] md:space-y-4"
      >
        <DrawerTitle className="">
          <div className="flex h-12 flex-none items-center justify-between p-4 pb-0">
            <div className="w-6">
              {showBackArrow && (
                <>
                  {step === "upgradeSelections" && (
                    <Button
                      variant="link"
                      type="button"
                      title={t.back}
                      className="bg-transparent p-0 text-gray-400"
                      onClick={() => setStep("upgradeInfo")}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} className="size-5" />
                    </Button>
                  )}
                </>
              )}
            </div>
            {showTitleSteps && (
              <p className="text-md font-normal text-black">
                {step === "upgradeInfo" ? "1" : "2"}/2{" "}
                {t.upgrade_banner.dialog_steps}
              </p>
            )}
            <Button
              variant="link"
              onClick={close}
              className="h-6 p-0 text-gray-400"
              title={t.additional_options.cancel}
            >
              <FontAwesomeIcon icon={faXmark} className="size-5" />
            </Button>
          </div>
        </DrawerTitle>
        <div className="flex-1 overflow-y-auto px-4 pb-4">
          {step === "upgradeInfo" ? (
            <AddOnContent isDialog={true} />
          ) : (
            <AddOnSelections
              checkedFilteredItems={checkedFilteredItems}
              setCheckedFilteredItems={setCheckedFilteredItems}
              filteredItems={filteredItems}
            />
          )}
        </div>

        <div className="align-center flex flex-none flex-col border-t border-gray-200 bg-white p-4 md:px-4">
          <div className="flex items-center justify-center gap-2 p-2">
            <div className="text-lg font-bold text-black">
              {shopConfig?.fanTicket?.price &&
                moneyToCurrency(shopConfig?.fanTicket.price)}
            </div>
            <div className="text-md text-black">
              {t.additional_options.per_ticket}
              {shopConfig?.fanTicket?.allowSingleTicket && "*"}
            </div>
          </div>
          {step === "upgradeInfo" ? (
            <>
              {showContinueButton && (
                <Button
                  variant="default"
                  type="button"
                  onClick={() => setStep("upgradeSelections")}
                  className="text-md text-black"
                  style={{
                    backgroundColor:
                      shopConfig.commonConfiguration?.sloganTextColor,
                  }}
                >
                  {t.additional_options.add}
                </Button>
              )}
            </>
          ) : (
            <Link
              href={continueTo === "checkout" ? Paths.CHECKOUT : Paths.CART}
              prefetch={false}
            >
              <Button
                variant="default"
                className="w-full text-md text-black"
                onClick={handleSaveFantickets}
                style={{
                  backgroundColor:
                    shopConfig.commonConfiguration?.sloganTextColor,
                }}
              >
                <FontAwesomeIcon icon={faCartShopping} className="size-4" />
                {showOnCart
                  ? t.upgrade_banner.add_to_cart
                  : t.upgrade_banner.add_and_proceed_to_cart}
              </Button>
            </Link>
          )}
          {showContinueButton && (
            <Link
              href={continueTo === "checkout" ? Paths.CHECKOUT : Paths.CART}
              prefetch={false}
              className="text-center"
            >
              <Button
                variant="link"
                onClick={close}
                className="p-0 text-gray-400"
              >
                {t.additional_options.continue}
              </Button>
            </Link>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
};
