import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { PlainMessage, proto3 } from "@bufbuild/protobuf";
import {
  Event,
  EventDetails,
} from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import { LocalTime } from "@egocentric-systems/ts-apis/types/v1/localtime_pb";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/nl";
import "dayjs/locale/pt";
import "dayjs/locale/ru";
import "dayjs/locale/es";
import "dayjs/locale/tr";
import { Money } from "@egocentric-systems/ts-apis/types/v1/money_pb";
import {
  Image_Variant,
  Image,
} from "@egocentric-systems/ts-apis/booking_gateway/v1/types_pb";
import { EstimatedDeliveryTime } from "@egocentric-systems/ts-apis/shop_config/types/v1/order_confirmation_pb";
import { ShopLanguage } from "@egocentric-systems/ts-apis/shop_config/types/v1/common_pb";
import { z } from "zod";
import { jwtDecode } from "jwt-decode";
import { CheckoutSchema } from "./client/schemas/checkout";
import { CartItem, Translations } from "./definitions";
import { Discount } from "@egocentric-systems/ts-apis/voucher/types/v1/usage_pb";
import { MathMoney } from "./MathMoney";
import { Voucher } from "@egocentric-systems/ts-apis/booking_gateway/v1/vouchers_pb";
import { Benefit } from "@egocentric-systems/ts-apis/voucher/types/v1/membership_pb";
import { SeatUpgrade } from "@egocentric-systems/ts-apis/booking/types/v1/order_pb";

dayjs.extend(utc);
dayjs.extend(timezone);

type GetInvalidAddressFieldsResponse = Array<{
  field: keyof z.infer<CheckoutSchema>["deliveryAddress"];
  message: string;
}>;

export function getInvalidAddressFields(
  address: z.infer<CheckoutSchema>["deliveryAddress"],
  messages: Translations["error_messages"],
): GetInvalidAddressFieldsResponse {
  if (!address) {
    return [
      {
        field: "name",
        message: messages.invalid_address,
      },
      {
        field: "locality",
        message: messages.locality_invalid,
      },
      {
        field: "postalCode",
        message: messages.postal_code_invalid,
      },
      {
        field: "addressLine1",
        message: messages.address_line_1_invalid,
      },
      {
        field: "countryCode",
        message: messages.country_invalid,
      },
    ];
  }

  const fields: GetInvalidAddressFieldsResponse = [];

  if ((address.name?.length ?? 0) === 0) {
    fields.push({
      field: "name",
      message: messages.invalid_address,
    });
  }

  if ((address.addressLine1?.length ?? 0) === 0) {
    fields.push({
      field: "addressLine1",
      message: messages.invalid_address,
    });
  }

  if ((address.postalCode?.length ?? 0) === 0) {
    fields.push({
      field: "postalCode",
      message: messages.invalid_address,
    });
  }

  if ((address.locality?.length ?? 0) === 0) {
    fields.push({
      field: "locality",
      message: messages.invalid_address,
    });
  }

  if ((address.countryCode?.length ?? 0) !== 2) {
    fields.push({
      field: "countryCode",
      message: messages.invalid_address,
    });
  }

  return fields;
}

export function logIfDev(...args: any[]): void {
  if (process.env.NODE_ENV === "development") {
    console.log(...args);
  }
}

const EST_TIME = proto3.getEnumType(EstimatedDeliveryTime);

export function getLocaleWithRegion(locale: string) {
  const defaultRegions = {
    en: "en_US",
    es: "es_ES",
    fr: "fr_FR",
    de: "de_DE",
    it: "it_IT",
    ja: "ja_JP",
    pt: "pt_BR",
    zh: "zh_CN",
    ru: "ru_RU",
    ko: "ko_KR",
    da: "da_DK",
    nl: "nl_NL",
    no: "no_NO",
    pl: "pl_PL",
    sv: "sv_SE",
    tr: "tr_TR",
    hu: "hu_HU",
    hr: "hr_HR",
  };

  return defaultRegions[locale as keyof typeof defaultRegions] || "en_US";
}

export function calculateCartExpireDelta(token: string | null): string | null {
  try {
    if (!token) return null;
    const { exp } = jwtDecode(token);

    if (typeof exp !== "number") {
      return null;
    }

    const diff = getTimeDiffFromExpirationDate(exp);

    if (!diff) {
      return null;
    }

    return diff;
  } catch {
    return null;
  }
}

export function localeToCurrencyCode(locale: string): string {
  switch (locale) {
    case "de":
      return "de-DE";
    case "en":
      return "en-US";
    case "fr":
      return "fr-FR";
    case "it":
      return "it-IT";
    case "es":
      return "es-ES";
    case "nl":
      return "nl-NL";
    case "pl":
      return "pl-PL";
    case "pt":
      return "pt-PT";
    default:
      return "en-US";
  }
}

export function formatCurrency(
  value: number,
  currency: string,
  locale: string = "de",
) {
  if (isNaN(value)) {
    return Intl.NumberFormat(localeToCurrencyCode(locale ?? "de"), {
      style: "currency",
      currency: currency,
    }).format(0);
  }

  return Intl.NumberFormat(localeToCurrencyCode(locale ?? "de"), {
    style: "currency",
    currency: currency,
  }).format(value);
}

export function moneyToCurrency(
  money: PlainMessage<Money> | undefined,
): string {
  if (!money) {
    return "";
  }

  const major = (money.major ?? 0) * 1e6;
  const minor = money.minor ?? 0;

  const value = (major + minor) / 1e6;

  let locale = "de";

  if (money.currency === "USD") {
    locale = "en";
  }

  return formatCurrency(value, money.currency, locale);
}

export function calculateDiscountedPrice(
  price: MathMoney,
  discount: PlainMessage<Discount>["typ"],
) {
  const minMoney = MathMoney({ currency: price.currency });

  if (discount.case === "fixedAmount") {
    const discountedValue = price.subtract(discount.value);

    return discountedValue.orMin(minMoney);
  } else if (discount.value) {
    const discountValue = price.scale(discount.value.percent / 100);

    const discountedValue = price.subtract(
      discountValue.orMax(discount.value.upperLimit),
    );

    return discountedValue.truncateAfter(2).orMin(minMoney);
  }

  return price;
}

export function alreadySomeFanticketUpgrades(items: CartItem[]): boolean {
  return items.some((item) => {
    return item?.upgrades?.includes(SeatUpgrade.FANTICKET);
  });
}

export function moneyToString(money: PlainMessage<Money> | undefined): string {
  if (!money) {
    return "";
  }

  const major = (money.major ?? 0) * 1e6;
  const minor = money.minor ?? 0;
  const value = (major + minor) / 1e6;

  return value.toFixed(2).toString();
}

export function moneyToNumber(money: PlainMessage<Money> | undefined): number {
  if (!money) return 0;

  const major = (money.major ?? 0) * 1e6;
  const minor = money.minor ?? 0;
  const value = (major + minor) / 1e6;

  return Math.round(value * 100) / 100;
}

export function numberToMoney(
  value: number,
  currency: string,
): PlainMessage<Money> {
  const v = value * 1e6;

  const major = Math.floor(v / 1e6);
  const minor = Math.round(v % 1e6);

  return {
    currency,
    major,
    minor,
  };
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateGoogleMapsUrl(
  event: PlainMessage<EventDetails> | null,
): string | null {
  if (!event) return null;

  if (!event?.venue) {
    return null;
  }

  let query = "";

  if (event.venue.name) {
    query += `${event.venue.name}, `;
  }

  if (event.venue.address?.name) {
    query += `${event.venue.address.name}, `;
  }

  if (event.venue.address?.locality) {
    query += `${event.venue.address.locality}, `;
  }

  if (event.venue.address?.addressLine1) {
    query += `${event.venue.address.addressLine1}, `;
  }

  if ((event.venue.address?.addressLine2?.length ?? 0) > 0) {
    query += `, ${event.venue.address?.addressLine2}`;
  }

  if (event.venue.address?.postalCode) {
    query += `${event.venue.address.postalCode}, `;
  }

  if (event.venue.address?.countryCode) {
    query += `${event.venue.address.countryCode}`;
  }

  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(query)}`;
}

export function replaceTextObjects(
  text: string,
  replacements: Record<number, string>,
) {
  return text.replace(/\{(\d+)\}/g, (_, key) => replacements[key] ?? "");
}

export function getMailLink(mail: string): string {
  return `mailto:${mail}`;
}

export function getPhoneLink(phone: string): string {
  return `tel:${phone}`;
}

export function hasNumber(myString: string) {
  return /\d/.test(myString);
}

export function localTimeToDayjs(
  time: PlainMessage<LocalTime> | undefined,
): Dayjs {
  if (!time) {
    return dayjs();
  }

  return dayjs.tz(time.time, time.timezone);
}

export function getTimeFormat(date: Dayjs, locale: string): string {
  if (locale === "de") {
    return "Uhr";
  }

  return date.format("A");
}

export function setDayjsLocale(locale: string): void {
  dayjs.locale(locale);
}

export const getLargestVariant = (
  image: PlainMessage<Image> | undefined,
): PlainMessage<Image_Variant> | undefined =>
  image?.variants.reduce((prev, current) =>
    prev.width > current.width ? prev : current,
  );

export const getSmallestVariant = (
  image: PlainMessage<Image> | undefined,
): PlainMessage<Image_Variant> | undefined =>
  image?.variants.reduce((prev, current) =>
    prev.width < current.width ? prev : current,
  );

export const getVariantByWidth = (
  width: number,
  image: PlainMessage<Image> | undefined,
): PlainMessage<Image_Variant> | undefined =>
  image?.variants.reduce((prev, current) => {
    const diffPrev = Math.abs(width - prev.width);
    const diffCurrent = Math.abs(width - current.width);

    if (diffPrev < diffCurrent) {
      return prev;
    }

    return current;
  });

export function getPlainVariantByWidth(
  width: number,
  image: PlainMessage<Image> | undefined,
): PlainMessage<Image_Variant> | undefined {
  if (!image) return undefined;

  const variant = image?.variants.reduce((prev, current) => {
    const diffPrev = Math.abs(width - prev.width);
    const diffCurrent = Math.abs(width - current.width);

    if (diffPrev < diffCurrent) {
      return prev;
    }

    return current;
  });

  if (!variant.width) {
    variant.width = width;
  }

  if (!variant.height) {
    variant.height = width;
  }

  return variant;
}

export function printEstimatedDeliveryTime(
  est: EstimatedDeliveryTime | undefined,
  daysText: string,
): string {
  if (est === undefined) {
    return `0 ${daysText}`;
  }

  const enumValue = EST_TIME.findNumber(est);

  if (!enumValue) {
    return `0 ${daysText}`;
  }

  const parts = enumValue.localName.split("_");

  const days = parts.reduce((acc, curr) => {
    const parsed = parseInt(curr);
    if (isNaN(parsed)) return acc;
    return parseInt(curr);
  }, 0);

  return `${days} ${daysText}`;
}

export function getTimeDiffFromExpirationDate(exp: number): string | null {
  const now = dayjs();
  const expirationDate = dayjs.unix(exp);

  if (!expirationDate.isValid() || expirationDate.isBefore(now)) {
    return null;
  }

  const diffInSeconds = expirationDate.diff(now, "seconds");
  const hours = Math.floor(diffInSeconds / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = diffInSeconds % 60;

  const formattedHours =
    hours > 0 ? hours.toString().padStart(2, "0") + ":" : "";
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
}

export function isLanguageSupported(lng: string): boolean {
  const values = proto3
    .getEnumType(ShopLanguage)
    .values.map((v) => v.localName.toLowerCase());

  return values.includes(lng);
}

export function validateAddress(
  address: z.infer<CheckoutSchema>["deliveryAddress"],
): boolean {
  if (!address) return false;
  if ((address.name?.length ?? 0) === 0) return false;
  if ((address.addressLine1?.length ?? 0) === 0) return false;
  if ((address.postalCode?.length ?? 0) === 0) return false;
  if ((address.locality?.length ?? 0) === 0) return false;
  if ((address.countryCode?.length ?? 0) !== 2) return false;
  return true;
}

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const isItemValidForDiscount = (
  item: CartItem,
  discount?: PlainMessage<Voucher | Benefit>,
) => {
  if (!discount || item.discount) {
    return false;
  }

  if (
    discount.eventSeriesIds.length &&
    !discount.eventSeriesIds.includes(item.series.id)
  ) {
    return false;
  }

  if (
    discount.eventIds.length &&
    item.eventOrBundle.case === "event" &&
    !discount.eventIds.includes(item.eventOrBundle.value.id)
  ) {
    return false;
  }

  if (
    discount.bundleIds.length &&
    item.eventOrBundle.case === "bundle" &&
    !discount.bundleIds.includes(item.eventOrBundle.value.id)
  ) {
    return false;
  }

  if (
    discount.priceGroupIds.length &&
    !discount.priceGroupIds.includes(item.section.priceGroupId)
  ) {
    return false;
  }

  if (discount.priceIds.length && !discount.priceIds.includes(item.price.id)) {
    return false;
  }

  return true;
};

export function filterHiddenBundleEvents<T extends Event | PlainMessage<Event>>(
  events?: Array<T>,
): Array<T> {
  const blacklistedEvents: string[] = [
    "PKSDQ3PXRCKRT73O3ZMWPAMVTY",
    "3BMUYVZINZ7KVTP6TJVAKB7NFI",
    "H2DGV4NDRYCQQ26IG37SGN3MOM",
    "5ZSA52SLUKJMUPSYM3T4UMGBBU",
    "H5BC6C2NYU5CKJIHSSET5YJ5EI",
    "LJV4KIVL3QMX3GIAHIGVRZVAYY",
    "Q3L2KU2PDPW3FTVIVOOFFJUFK4",
    "WBE2IFXBATO5JJBHSFWGRJ46OE",
    "YH3HOBFBSSJY2CJO4XSQATL7BY",
    "53J3VFMUHY5MO76WNTREUA6AAE", // DTM Truck Shop
  ].map((e) => e.toLowerCase());

  return (
    events?.filter(
      (event) => !blacklistedEvents.includes(event.id.toLowerCase()),
    ) ?? []
  );
}
