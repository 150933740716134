"use client";

import { Dispatch, JSX, SetStateAction } from "react";
import { useMediaQuery } from "usehooks-ts";
import { getSmallestVariant, localTimeToDayjs } from "~/lib/utils";
import { FALLBACK_IMAGE } from "~/app/cart/_components/AddOns/constants";
import Image from "next/image";
import { useShopConfig } from "~/hooks/useShopConfig";
import { Checkbox } from "~/components/ui/checkbox";
import { Label } from "~/components/ui/label";
import { useTranslations } from "~/hooks/useTranslations";
import { Badge } from "~/components/ui/badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { CartItem } from "~/lib/definitions";

export function AddOnSelections({
  checkedFilteredItems,
  setCheckedFilteredItems,
  filteredItems,
}: {
  checkedFilteredItems: number[];
  setCheckedFilteredItems: Dispatch<SetStateAction<number[]>>;
  filteredItems: CartItem[];
}): JSX.Element {
  const {
    shopConfig: { fanTicket },
  } = useShopConfig();
  const t = useTranslations();
  const fanTicketThumbnail = fanTicket?.imageVariants?.[0];
  const isMobile = useMediaQuery("(max-width: 768px)");

  const allChecked = checkedFilteredItems.length === filteredItems.length;

  const handleCheckboxChange = (index: number) => {
    setCheckedFilteredItems((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  const handleSelectAll = () => {
    setCheckedFilteredItems((prev) =>
      prev.length === filteredItems.length
        ? []
        : filteredItems.map((_, index) => index),
    );
  };

  return (
    <>
      <div className="flex-none md:space-y-6">
        <div className="space-y-2">
          <p className="bg-white py-2 text-center text-lg font-bold md:p-0 md:text-xl md:leading-xl">
            {t.upgrade_banner.dialog_step_2_intro}
          </p>
          {!isMobile && (
            <p className="text-center text-md leading-xl text-black">
              {t.upgrade_banner.dialog_step_2_text}
            </p>
          )}
        </div>
        <div className="flex h-[75px] items-center gap-4 overflow-hidden rounded-md bg-black font-bold text-white">
          <Image
            width={fanTicketThumbnail?.dimensions?.width}
            height={fanTicketThumbnail?.dimensions?.height}
            alt={fanTicketThumbnail?.url ?? ""}
            src={fanTicketThumbnail?.url ?? FALLBACK_IMAGE}
            className="h-[75px] w-auto"
          />
          {t.upgrade_banner.fantickets}
        </div>

        <div className="flex items-center space-x-2 p-4 md:py-0">
          <Checkbox
            className="size-4 md:size-6"
            name="checkAll"
            id="checkAll"
            checked={allChecked}
            onCheckedChange={handleSelectAll}
          />
          <Label
            htmlFor="checkAll"
            className="cursor-pointer border border-white text-md"
          >
            {t.upgrade_banner.select_all} ({filteredItems.length})
          </Label>
        </div>
      </div>
      <ul className="flex w-full flex-1 flex-col gap-3 overflow-y-auto md:mb-8">
        {filteredItems.map((item, index) => {
          const itemId = item?.eventOrBundle?.value?.id + index;
          const thumbnail = getSmallestVariant(
            item.eventOrBundle.value.thumbnail,
          );

          return (
            <li key={itemId}>
              <Label
                htmlFor={itemId}
                className="flex w-full cursor-pointer flex-row gap-4 rounded-xl border border-solid border-gray-200 bg-white p-4"
              >
                <div>
                  <Checkbox
                    className="size-4 md:size-6"
                    id={itemId}
                    checked={checkedFilteredItems.includes(index)}
                    onCheckedChange={() => handleCheckboxChange(index)}
                  />
                </div>
                {thumbnail && (
                  <Image
                    src={thumbnail.url}
                    width={thumbnail.width}
                    height={thumbnail.height}
                    alt={item.eventOrBundle.value.name}
                    className="hidden h-full w-full rounded-md object-cover object-center md:flex md:max-h-[100px] md:max-w-[150px]"
                  />
                )}
                <div className="flex w-full flex-col gap-1">
                  <p className="line-clamp-2 w-full max-w-full text-lg font-bold leading-sm lg:w-auto">
                    {item.eventOrBundle.value.name}
                  </p>
                  <p className="line-clamp-1 text-sm leading-sm text-gray-600">
                    {item.series.name}
                  </p>

                  <p className="space-x-2 text-sm">
                    <span>{item.section.name}</span>
                    {item.seat && (
                      <span>
                        {`, ${t.select_price.row} ${item.seat.row}, ${t.select_price.seat} ${item.seat.name}`}
                      </span>
                    )}
                    <span>&bull;</span>
                    <span>{item.price.name}</span>
                  </p>
                  <div className="flex flex-row gap-2 pt-2">
                    <Badge className="py-2">
                      {localTimeToDayjs(item.eventOrBundle.value.start).format(
                        "DD.MM.YYYY",
                      )}
                    </Badge>
                    {checkedFilteredItems.includes(index) && (
                      <div>
                        <Badge
                          variant="outline"
                          className="gap-2 whitespace-nowrap border border-gray-300 bg-[#e6f4ff] p-2"
                        >
                          <FontAwesomeIcon icon={faStar} />
                          <p>{t.upgrades.fanticket}</p>
                        </Badge>
                      </div>
                    )}
                  </div>
                </div>
              </Label>
            </li>
          );
        })}
      </ul>
    </>
  );
}
