"use client";

import { JSX, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import Image from "next/image";
import { alreadySomeFanticketUpgrades, cn, moneyToCurrency } from "~/lib/utils";
import { Button } from "~/components/ui/button";
import { useTranslations } from "~/hooks/useTranslations";
import { useCart } from "~/hooks/useCart";
import showdown from "showdown";
import { useAddOnAvailable } from "~/app/cart/_components/AddOns/useAddOnAvailable";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FALLBACK_IMAGE } from "~/app/cart/_components/AddOns/constants";
import { useShopConfig } from "~/hooks/useShopConfig";
import { useLocale } from "~/hooks/useLocale";
import { AddOnPopover } from "./AddOnPopover";

interface IAddOnContent {
  isDialog?: boolean;
}

export function AddOnContent({
  isDialog = false,
}: Readonly<IAddOnContent>): JSX.Element {
  const t = useTranslations();
  const {
    shopConfig: { fanTicket },
  } = useShopConfig();

  const locale = useLocale();
  const { items } = useCart();
  const addOnAvailable = useAddOnAvailable();

  const alreadyItemsUpgraded = useMemo(
    () => alreadySomeFanticketUpgrades(items),
    [items],
  );

  const description = useMemo(() => {
    const defaultLanguage = locale ?? fanTicket?.description?.defaultLanguage;

    return new showdown.Converter().makeHtml(
      fanTicket?.description?.translations?.[defaultLanguage] ??
        t.additional_options.your_fantickets,
    );
  }, [fanTicket, locale, t.additional_options.your_fantickets]);

  const fanTicketThumbnail = useMemo(() => {
    return fanTicket?.imageVariants.reduce((prev, current) =>
      Number(prev.dimensions?.width) > Number(current.dimensions?.width)
        ? prev
        : current,
    );
  }, [fanTicket]);

  return addOnAvailable ? (
    <>
      {isDialog ? (
        <div className="space-y-2">
          <h1 className="line-clamp-2 bg-white py-2 text-center text-lg font-bold md:p-0 md:text-xl md:leading-xl">
            {t.upgrade_banner.dialog_step_1_intro}
          </h1>
          <p className="hidden text-center text-md leading-xl text-black md:block">
            {t.upgrade_banner.dialog_step_1_text}
          </p>
        </div>
      ) : (
        <h1 className="mb-8 text-2xl font-bold leading-xl">
          {t.additional_options.title}
        </h1>
      )}
      <div className="flex flex-col items-center md:items-start">
        <div
          style={{
            backgroundColor: fanTicket?.headerBg ?? "#FFCC00",
            color: fanTicket?.headerFg ?? "#000",
          }}
          className={cn(
            "flex w-full max-w-[400px] gap-2 rounded-t-xl px-6 py-2 align-middle md:max-w-full",
            isDialog && "hidden md:flex",
          )}
        >
          <div>
            <FontAwesomeIcon icon={faStar} className="size-6" />
          </div>
          <p className="text-md">{t.additional_options.your_fantickets}</p>
        </div>
        <div className="flex w-full max-w-[400px] flex-col gap-4 rounded-b-[10px] bg-white py-4 md:max-w-[100%] md:flex-row md:gap-6 md:border md:border-gray-200 md:p-4 md:align-middle">
          <div className="min-w-[230px] max-w-[400px] md:max-w-[300px]">
            <Image
              width={fanTicketThumbnail?.dimensions?.width}
              height={fanTicketThumbnail?.dimensions?.height}
              alt={fanTicketThumbnail?.url ?? ""}
              src={fanTicketThumbnail?.url ?? FALLBACK_IMAGE}
              className="w-full rounded-md object-cover object-center"
            />
            <div
              className={cn(
                "mt-4 hidden items-center gap-[8px]",
                isDialog && "md:flex",
              )}
            >
              <div className="text-lg font-bold text-black">
                {fanTicket?.price && moneyToCurrency(fanTicket.price)}
              </div>
              <div className="text-md text-black">
                {t.additional_options.per_ticket}
                {fanTicket?.allowSingleTicket && "*"}
              </div>
            </div>
          </div>
          <div
            className={cn("flex w-full gap-2 md:hidden", !isDialog && "hidden")}
          >
            <FontAwesomeIcon icon={faStar} className="size-5" />
            <p className="text-md font-bold">
              {t.additional_options.your_fantickets}
            </p>
          </div>
          <div className="w-[100%] text-sm leading-md text-gray-600 md:leading-lg md:text-black">
            <div dangerouslySetInnerHTML={{ __html: description }} />

            {fanTicket?.allowSingleTicket &&
              t.additional_options.whole_order_only}

            {!isDialog && (
              <div className="mt-6 flex w-full flex-row items-center justify-end gap-4">
                {alreadyItemsUpgraded && (
                  <AddOnPopover
                    continueTo={"cart"}
                    showStep={"upgradeSelections"}
                    showBackArrow={false}
                    showTitleSteps={false}
                    showContinueButton={false}
                    showOnCart={true}
                  >
                    {({ onOpen }) => (
                      <Button
                        variant="link"
                        onClick={onOpen}
                        className="p-0 pl-4 text-md text-gray-400"
                      >
                        {t.upgrade_banner.edit_selection}
                      </Button>
                    )}
                  </AddOnPopover>
                )}
                <AddOnPopover
                  continueTo={"cart"}
                  showStep={"upgradeSelections"}
                  showBackArrow={false}
                  showTitleSteps={false}
                  showContinueButton={false}
                  showOnCart={true}
                >
                  {({ onOpen }) => (
                    <Button
                      variant="default"
                      type="button"
                      className={cn(
                        "font-bold",
                        alreadyItemsUpgraded && "bg-green-500",
                      )}
                      disabled={alreadyItemsUpgraded}
                      onClick={onOpen}
                    >
                      {alreadyItemsUpgraded ? (
                        <div className="font-bold">
                          <FontAwesomeIcon icon={faCheck} />
                          {t.additional_options.already_added}
                        </div>
                      ) : (
                        t.additional_options.add
                      )}
                    </Button>
                  )}
                </AddOnPopover>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}
